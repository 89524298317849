<template>
  <div class="page_content page_content_orders">
    <div class="content_header d-flex align-items-center">
      <span>訂單管理</span>
    </div>
    <section class="content">
      <div class="container-fluid">
        <div class="search_section">
          <div class="form-group d-flex align-items-center">
            <label for="system">系統</label>
            <select
              id="system"
              v-model="search_v2.system"
              name="系統"
              class="form-control search_brand"
              :class="{'is-invalid': errors.has('search.系統')}"
              data-vv-scope="search"
            >
              <option
                :value="''"
              >
                不限
              </option>
              <option
                v-for="item in system_data"
                :key="item.systemCode"
                :value="item.systemCode"
              >
                {{ item.systemName }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex align-items-center">
            <label>搜尋模式</label>
            <div class="form-check form-check-inline">
              <input
                id="search_mode_orderdate"
                v-model="search_v2.mode"
                class="form-check-input"
                type="radio"
                :value="1"
                @change="switch_searchmode()"
              >
              <label
                class="form-check-label"
                for="search_mode_orderdate"
              >訂購日期</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                id="search_mode_delivery"
                v-model="search_v2.mode"
                class="form-check-input"
                type="radio"
                :value="2"
                @change="switch_searchmode()"
              >
              <label
                class="form-check-label"
                for="search_mode_delivery"
              >到貨日期</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                id="search_mode_keyword"
                v-model="search_v2.mode"
                class="form-check-input"
                type="radio"
                :value="3"
                @change="switch_searchmode()"
              >
              <label
                class="form-check-label"
                for="search_mode_keyword"
              >關鍵字</label>
            </div>
          </div>
          <div
            v-if="search_v2.mode !== 3"
            class="form-group d-flex align-items-center"
          >
            <label for="saerch_orderdate">起始日期</label>
            <input
              id="saerch_orderdate"
              v-model="search_v2.dt_start"
              v-validate="'required'"
              type="date"
              name="起始日期"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.起始日期')}"
              data-vv-scope="search"
              @change="same_date()"
            >
          </div>
          <div
            v-if="search_v2.mode !== 3"
            class="form-group d-flex align-items-center"
          >
            <label for="saerch_deliverydate">結束日期</label>
            <input
              id="saerch_deliverydate"
              v-model="search_v2.dt_end"
              v-validate="'required'"
              type="date"
              name="結束日期"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.結束日期')}"
              data-vv-scope="search"
            >
          </div>
          <div
            v-if="search_v2.mode !== 3"
            class="form-group d-flex align-items-center"
          >
            <input
              v-model="search_same_date"
              type="checkbox"
              name="同步日期"
              class="form-control form_input"
              @change="same_date()"
            >
            <label for="search_keyword">同步日期</label>
          </div>
          <div
            v-if="search_v2.mode === 3"
            class="form-group d-flex align-items-center"
          >
            <label for="search_value">關鍵字</label>
            <input
              id="search_value_keyword"
              v-model="search_v2.value_keyword"
              v-validate="'required'"
              type="text"
              name="關鍵字"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.關鍵字')}"
              data-vv-scope="search"
              placeholder="輸入關鍵字"
            >
          </div>
        </div>
        <!-- <div class="search_section">
          <div class="form-group d-flex align-items-center">
            <label for="system">系統</label>
            <select
              id="system"
              v-model="search.system"
              name="系統"
              class="form-control search_brand"
              :class="{'is-invalid': errors.has('search.系統')}"
              data-vv-scope="search"
            >
              <option
                :value="''"
              >
                不限
              </option>
              <option
                v-for="item in system_data"
                :key="item.systemCode"
                :value="item.systemCode"
              >
                {{ item.systemName }}
              </option>
            </select>
          </div>
          <div class="form-group d-flex align-items-center">
            <label>搜尋模式</label>
            <div class="form-check form-check-inline">
              <input
                id="search_mode_orderdate"
                v-model="search.mode"
                class="form-check-input"
                type="radio"
                :value="1"
                @change="switch_searchmode()"
              >
              <label
                class="form-check-label"
                for="search_mode_orderdate"
              >訂購日期</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                id="search_mode_delivery"
                v-model="search.mode"
                class="form-check-input"
                type="radio"
                :value="2"
                @change="switch_searchmode()"
              >
              <label
                class="form-check-label"
                for="search_mode_delivery"
              >到貨日期</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                id="search_mode_keyword"
                v-model="search.mode"
                class="form-check-input"
                type="radio"
                :value="3"
                @change="switch_searchmode()"
              >
              <label
                class="form-check-label"
                for="search_mode_keyword"
              >關鍵字</label>
            </div>
          </div>
          <div
            v-if="search.mode === 1"
            class="form-group d-flex align-items-center"
          >
            <label for="saerch_orderdate">訂購日期</label>
            <input
              id="saerch_orderdate"
              v-model="search.date"
              v-validate="'required'"
              type="date"
              name="訂購日期"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.訂購日期')}"
              data-vv-scope="search"
            >
          </div>
          <div
            v-if="search.mode === 2"
            class="form-group d-flex align-items-center"
          >
            <label for="saerch_deliverydate">到貨日期</label>
            <input
              id="saerch_deliverydate"
              v-model="search.date"
              v-validate="'required'"
              type="date"
              name="到貨日期"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.到貨日期')}"
              data-vv-scope="search"
            >
          </div>
          <div
            v-if="search.mode === 3"
            class="form-group d-flex align-items-center"
          >
            <label for="search_value">關鍵字</label>
            <input
              id="search_value"
              v-model="search.value"
              v-validate="'required'"
              type="text"
              name="關鍵字"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.關鍵字')}"
              data-vv-scope="search"
              placeholder="輸入訂購人姓名或手機"
            >
          </div>
          <a
            href="#"
            class="search_btn d-flex align-items-center justify-content-center"
            @click.prevent="get_orders()"
          >
            <span>搜尋</span>
          </a>
        </div> -->
        <div class="search_section">
          <div class="form-group d-flex align-items-center">
            <input
              id="search_adv_keyword"
              v-model="search_v2.option_adv_keyword"
              type="checkbox"
              name="進階搜尋"
              class="form-control form_input"
            >
            <label for="search_adv_keyword">進階搜尋</label>
          </div>
          <div class="form-group d-flex align-items-center">
            <input
              id="search_adv_keyword_value"
              v-model="search_v2.value_adv_keyword"
              type="text"
              name="進階搜尋"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.進階搜尋')}"
              data-vv-scope="search"
              placeholder="輸入進階搜尋"
            >
          </div>
          <div class="form-group d-flex align-items-center">
            <input
              id="search_unpay"
              v-model="search_v2.option_unpay"
              type="checkbox"
              name="未付款"
              class="form-control form_input"
            >
            <label for="search_unpay">未付款</label>
          </div>
          <div class="form-group d-flex align-items-center">
            <input
              id="search_sort"
              v-model="search_v2.option_sort"
              type="checkbox"
              name="時間順排序"
              class="form-control form_input"
            >
            <label for="search_sort">時間順排序</label>
          </div>
          <div class="form-group d-flex align-items-center">
            <label for="search_row">搜尋筆數</label>
            <input
              id="search_row"
              v-model="search_v2.option_row"
              type="text"
              name="搜尋筆數"
              class="form-control form_input"
              :class="{'is-invalid': errors.has('search.搜尋筆數')}"
              data-vv-scope="search"
              placeholder="輸入搜尋筆數"
            >
          </div>
          <a
            href="#"
            class="search_btn d-flex align-items-center justify-content-center"
            @click.prevent="get_orders()"
          >
            <span>搜尋</span>
          </a>
        </div>
        <div
          v-if="order_content === 'list'"
          class="search_section"
        >
          <div class="form-group d-flex align-items-center">
            <label for="search_advanced">進階</label>
            <select
              id="system"
              v-model="search_advanced"
              name="進階"
              class="form-control search_brand"
            >
              <option
                value
                disabled
                selected
                hidden
              >
                選項
              </option>
              <option value="">
                不限
              </option>
              <option
                v-for="item in option.platform"
                :key="item.platformCode"
                :value="item.platformCode"
              >
                {{ item.platformName === '讀取器' ? '匯入' : item.platformName }}
              </option>
            </select>
          </div>
          <div
            v-if="orders.length !== 0"
            class="form-group d-flex align-items-center m-0"
          >
            <select
              v-model="pagination.filter_page_size"
              class="form-control"
            >
              <template v-if="Math.ceil(orders.length / 10) > 10">
                <option
                  v-for="(item, index) in 9"
                  :key="index"
                  :value="(item + 1) * 10"
                >
                  {{ (item + 1) * 10 }}
                </option>
              </template>
              <template v-else>
                <option
                  v-for="(item, index) in Math.ceil(orders.length / 10)"
                  :key="index"
                  :value="(item + 1) * 10"
                >
                  {{ (item + 1) * 10 }}
                </option>
              </template>
            </select>
          </div>
        </div>
        <div v-if="order_content === 'list'">
          <div
            v-show="filter_Data.length > 0"
            class="table-responsive"
          >
            <table class="table table-borderless orders_table table-hover">
              <thead class="thead-light">
                <tr>
                  <th
                    scope="col"
                  >
                    訂購日期
                  </th>
                  <th
                    scope="col"
                  >
                    訂單編號/ERP/狀態
                  </th>
                  <th scope="col">
                    訂購人
                  </th>
                  <th scope="col">
                    收件人
                  </th>
                  <th
                    scope="col"
                  >
                    帳款
                  </th>
                  <th
                    scope="col"
                  >
                    到貨日
                  </th>
                  <th
                    scope="col"
                  >
                    <span class="mr-1">總價</span>
                    <i
                      v-if="sort_amount_status"
                      class="icon-chevron-thin-down cursor_pointer"
                      @click="sort_data('amountTotal')"
                    />
                    <i
                      v-else
                      class="icon-chevron-thin-up cursor_pointer"
                      @click="sort_data('amountTotal')"
                    />
                  </th>
                  <th scope="col">
                    備註
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="order_detail in filter_Data"
                  :key="order_detail.orderSerial"
                  :class="{ 'table_disabled': !order_detail.status }"
                >
                  <td>
                    <div>{{ order_detail.orderDate }}</div>
                    <a
                      v-if="order_detail.status"
                      href="#"
                      class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                      @click.prevent="cancel_order(order_detail)"
                    >取消訂單</a>
                  </td>
                  <td>
                    <a
                      href="#"
                      class="text-color_primary"
                      @click.prevent="get_oder_detail(order_detail.orderSerial)"
                    >
                      {{ order_detail.orderSerial }}
                    </a>
                    <div>{{ order_detail.erpSerial }}</div>
                    <div>{{ order_detail.receiptType }} {{ order_detail.receiptNumber }}</div>
                  </td>
                  <td>
                    <div>{{ order_detail.purFullname }}</div>
                    <div>{{ order_detail.purCelnum }}</div>
                    <div>{{ order_detail.purAddress }}</div>
                  </td>
                  <td>
                    <div>{{ order_detail.recFullname }}</div>
                    <div>{{ order_detail.recCelnum }}</div>
                    <div>{{ order_detail.recAddress }}</div>
                  </td>
                  <td>
                    <div
                      v-for="(bill, index) in order_detail.bill"
                      :key="index"
                      class="d-flex"
                    >
                      <div
                        class="d-flex flex-column"
                      >
                        <span>{{ bill.paymentName }}</span>
                        <span
                          v-if="bill.paymentMode === '付款'"
                        >
                          <span
                            v-if="bill.payStatus || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account'"
                            :class="{ 'text-success': bill.payStatus || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account' }"
                          >
                            <span v-if="bill.payStatus">
                              已付款 {{ bill.amount | currency }}
                            </span>
                            <span
                              v-else
                            >程序付款 {{ bill.amount | currency }}
                            </span>
                          </span>
                          <span
                            v-else
                          >
                            <a
                              v-if="order_detail.status"
                              class="d-inline-block btn btn_outline_danger rounded-sm px-2"
                              href="#"
                              @click.prevent="confirm_order_item(order_detail, bill)"
                            >未付款 {{ bill.amount | currency }}</a>
                            <span
                              v-else
                              class="d-inline-block rounded-sm px-2"
                            >已取消 {{ bill.amount | currency }}</span>
                          </span>
                        </span>
                        <span
                          v-else
                        >
                          <span class="d-inline-block rounded-sm px-2">退款 {{ bill.amount | currency }}</span>
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div>{{ order_detail.arriveDate }}</div>
                    <div class="text-danger">
                      {{ order_detail.arriveTime }}
                    </div>
                    <div class="d-flex">
                      <img
                        :class="order_detail.outsideDepotCode.includes('903') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/harvest.png"
                      >
                      <img
                        :class="order_detail.outsideDepotCode.includes('904') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/fruits.png"
                      >
                      <img
                        :class="order_detail.outsideDepotCode.includes('905') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/hazmat.png"
                      >
                      <img
                        :class="order_detail.outsideDepotCode.includes('906') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/pie.png"
                      >
                      <img
                        :class="order_detail.outsideDepotCode.includes('908') ? 'order_icon' : 'order_icon_none'"
                        src="@/assets/images/electronics.png"
                      >
                    </div>
                  </td>
                  <td>
                    <div>{{ order_detail.amountTotal | currency }}</div>
                    <div v-if="order_detail.amountReturn === 0" />
                    <div v-else>
                      {{ order_detail.amountReturn | currency }}
                    </div>
                    <div v-if="order_detail.amountReturn === 0 || order_detail.amountTotal === order_detail.amountValue" />
                    <div v-else>
                      {{ order_detail.amountValue | currency }}
                    </div>
                  </td>
                  <td>
                    <div v-if="order_detail.memoCustomer === ''">
                      x
                    </div>
                    <div v-else>
                      {{ order_detail.memoCustomer }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <ul
            v-if="filter_Data.length > 0"
            class="pagination"
          >
            <li
              class="pagination-item"
              :class="{ 'pagination-item-disabled': !pagination.has_pre }"
            >
              <a
                href="#"
                class="pagination-link"
                aria-label="Previous"
                @click.prevent="change_page(pagination.current_page - 1)"
              >
                <i class="icon-chevron-thin-left" />
              </a>
            </li>
            <li
              v-for="(page, index) in pagination.filter_range_page"
              :key="index"
              class="pagination-item"
            >
              <a
                href="#"
                class="pagination-link"
                :class="{ 'pagination-link-active': pagination.current_page === page.index }"
                @click.prevent="change_page(page.index)"
              >{{ page.index }}</a>
            </li>
            <li
              class="pagination-item"
              :class="{ 'pagination-item-disabled': !pagination.has_next }"
            >
              <a
                href="#"
                class="pagination-link"
                aria-label="Next"
                @click.prevent="change_page(pagination.current_page + 1)"
              >
                <i class="icon-chevron-thin-right" />
              </a>
            </li>
          </ul>
        </div>
        <div
          v-if="order_content === 'detail'"
          class="order_section"
        >
          <div class="section_header justify-content-between">
            <span>訂單細節</span>
            <a
              href="#"
              class="btn btn_default rounded-0"
              @click.prevent="order_content = 'list', switch_nav('', false)"
            >返回</a>
          </div>
          <div class="section_body">
            <ul class="nav nav-tabs mb-3">
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'order_info' }"
                  @click.prevent="switch_nav('order_info', true)"
                >訂單資料</a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'order_content' }"
                  @click.prevent="switch_nav('order_content', true)"
                >訂購內容</a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'order_contact' }"
                  @click.prevent="switch_nav('order_contact', true)"
                >聯絡資訊</a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'order_price' }"
                  @click.prevent="switch_nav('order_price', true)"
                >價格資訊</a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'order_bill' }"
                  @click.prevent="switch_nav('order_bill', true)"
                >帳款</a>
              </li>
              <li class="nav-item">
                <a
                  href="#"
                  class="nav-link"
                  :class="{ 'active': nav_tabs === 'order_receipt' }"
                  @click.prevent="switch_nav('order_receipt', true)"
                >發票</a>
              </li>
            </ul>
            <div class="container-fluid">
              <div class="d-flex mb-2">
                <a
                  v-if="order.status && (nav_tabs === 'order_info' || nav_tabs === 'order_contact')"
                  href="#"
                  class="btn btn_primary px-2 rounded-sm"
                  @click.prevent="order_mode = 'edit_rec', filter_district()"
                >修改收件資料</a>
                <a
                  v-if="order.status && order_mode === 'edit_rec'"
                  href="#"
                  class="btn btn_primary px-2 ml-2 rounded-sm"
                  @click.prevent="update_rec()"
                >更新收件資料</a>
                <a
                  v-if="order.status && nav_tabs === 'order_receipt'"
                  href="#"
                  class="btn btn_primary px-2 ml-2 rounded-sm"
                  @click.prevent="order_mode = 'edit_receipt'"
                >修改發票資料</a>
                <a
                  v-if="order.status && order_mode === 'edit_receipt'"
                  href="#"
                  class="btn btn_primary px-2 ml-2 rounded-sm"
                  @click.prevent="update_receipt()"
                >更新發票資料</a>
              </div>
              <div
                v-if="nav_tabs === 'order_info'"
                class="row"
              >
                <div class="col-12 col-xl-4">
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂單狀態</span>
                      <span :class="{ 'text-success': order.status, 'text-danger': !order.status }">{{ order.status ? '建立成功' : '已取消' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂單編號</span>
                      <span>{{ order.orderSerial }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>系統/來源</span>
                      <span>{{ order.systemCode }}/{{ order.platformCode }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂購日期</span>
                      <span>{{ order.orderDate }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>下單人</span>
                      <span>{{ order.adminId }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="delivery">運送方式</label>
                      <select
                        id="delivery"
                        v-model="order.delivery"
                        v-validate="'required'"
                        name="運送方式"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('edit.運送方式')}"
                        data-vv-scope="edit"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          運送方式
                        </option>
                        <option
                          v-for="item in option.delivery"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>運送方式</span>
                      <span>{{ order.delivery }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>回饋碼</span>
                      <span>{{ order.deliverySerial }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <div
                        v-if="order_mode === 'edit_rec'"
                        class="form-group d-flex align-items-center"
                      >
                        <label for="memoCustomer">客戶備註</label>
                        <input
                          id="memoCustomer"
                          v-model="order.memoCustomer"
                          type="text"
                          name="客戶備註"
                          class="form-control form_input"
                        >
                      </div>
                      <div
                        v-else
                        class="d-flex align-items-baseline"
                      >
                        <span>客戶備註</span>
                        <span>{{ order.memoCustomer }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-baseline"
                    >
                      <label for="recAddrDetail">到貨地址</label>
                      <div class="d-flex flex-column">
                        <div class="d-flex">
                          <select
                            id="city"
                            v-model="order.rec.recAddrCountyName"
                            v-validate="'required'"
                            name="縣市"
                            class="form-control form_input"
                            :class="{'is-invalid': errors.has('edit.縣市')}"
                            data-vv-scope="edit"
                            @change="filter_district()"
                          >
                            <option
                              value
                              disabled
                              selected
                              hidden
                            >
                              縣市
                            </option>
                            <option
                              v-for="item in option.address"
                              :key="item.addrCountyCode"
                              :value="item.addrCountyName"
                            >
                              {{ item.addrCountyName }}
                            </option>
                          </select>
                          <select
                            id="district"
                            v-model="order.rec.recAddrCityName"
                            v-validate="'required'"
                            name="行政區"
                            class="form-control form_input"
                            :class="{'is-invalid': errors.has('edit.行政區')}"
                            data-vv-scope="edit"
                            @change="get_city_code()"
                          >
                            <option
                              value
                              disabled
                              selected
                              hidden
                            >
                              行政區
                            </option>
                            <option
                              v-for="item in district_data"
                              :key="item.addrCityCode"
                              :value="item.addrCityName"
                            >
                              {{ item.addrCityName }}
                            </option>
                          </select>
                        </div>
                        <input
                          id="recAddrDetail"
                          v-model="order.rec.recAddrDetail"
                          v-validate="'required'"
                          type="text"
                          name="詳細地址"
                          class="form-control form_input mt-2"
                          :class="{'is-invalid': errors.has('edit.詳細地址')}"
                          data-vv-scope="edit"
                        >
                      </div>
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>到貨地址</span>
                      <span>{{ order.rec.recAddrCountyName + order.rec.recAddrCityName + order.rec.recAddrDetail }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="arriveDate">到貨日期</label>
                      <input
                        id="arriveDate"
                        v-model="order.arriveDate"
                        v-validate="'required'"
                        type="date"
                        name="到貨日期"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('edit.到貨日期')}"
                        data-vv-scope="edit"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>到貨日期</span>
                      <span>{{ order.arriveDate }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="arriveTime">到貨時段</label>
                      <select
                        id="arriveTime"
                        v-model="order.arriveTime"
                        v-validate="'required'"
                        name="到貨時段"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('edit.到貨時段')}"
                        data-vv-scope="edit"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          到貨時段
                        </option>
                        <option
                          v-for="item in option.arriveTime"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>到貨時段</span>
                      <span>{{ order.arriveTime }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>設定轉ERP</span>
                      <span>{{ order.erpStatus ? '是' : '否' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>已轉ERP</span>
                      <span>{{ order.erpTransStatus ? '是' : '否' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>ERP單號</span>
                      <span>{{ order.erpSerial }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>IP</span>
                      <span>{{ order.ip }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-4">
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>出貨狀態</span>
                      <span>{{ order.storageProcessedStatus ? '已出貨' : '未出貨' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>出貨日期</span>
                      <span>{{ order.storageProcessedDate }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>強制免運</span>
                      <span>{{ order.option.optionNoTransFee ? '是' : '否' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>急件</span>
                      <span>{{ order.option.optionUrgent ? '是' : '否' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>分溫層寄送</span>
                      <span>{{ order.option.optionSplitup ? '是' : '否' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>是否附DM</span>
                      <span>{{ order.option.optionDm ? '是' : '否' }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="nav_tabs === 'order_content'"
                class="table-responsive"
              >
                <table class="table table-borderless table-hover">
                  <thead class="">
                    <tr>
                      <th
                        scope="col"
                        width="30px"
                        class="text-color_primary"
                      >
                        項目
                      </th>
                      <th
                        scope="col"
                        width="150px"
                        class="text-color_primary"
                      >
                        編號
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        名稱
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        規格
                      </th>
                      <th
                        scope="col"
                        width="100px"
                        class="text-center text-color_primary"
                      >
                        運送
                      </th>
                      <th
                        scope="col"
                        width="130px"
                        class="text-center text-color_primary"
                      >
                        價格/商品類型
                      </th>
                      <th
                        scope="col"
                        width="100px"
                        class="text-center text-color_primary"
                      >
                        原價/單價
                      </th>
                      <th
                        scope="col"
                        width="100px"
                        class="text-center text-color_primary"
                      >
                        數量/退貨
                      </th>
                      <th
                        scope="col"
                        width="80px"
                        class="text-right text-color_primary"
                      >
                        小計
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(order_item,index) in order.items"
                      :key="index"
                    >
                      <td>
                        <div class="form-check form-check-inline">
                          <input
                            :id="'check' + order_item.serialNumber"
                            class="form-check-input"
                            type="checkbox"
                            :checked="return_items.find((itm) => itm.serialNumber === order_item.serialNumber)"
                            :disabled="!order_item.optionReturn"
                            @click="item_check(order_item, $event)"
                          >
                        </div>
                      </td>
                      <td>{{ order_item.prodSerial }}</td>
                      <td>{{ order_item.prodName }}</td>
                      <td>{{ order_item.standard }}</td>
                      <td class="text-center">
                        {{ order_item.transportType }}
                      </td>
                      <td class="text-center">
                        <div>{{ order_item.priceType }}</div>
                        <div :class="order_item.prodMode == '退貨品' ? 'text-danger' : ''">
                          {{ order_item.prodMode }}
                        </div>
                      </td>
                      <td class="text-center">
                        <div>{{ order_item.priceOriginal | currency }}</div>
                        <div class="text-danger">
                          {{ order_item.price | currency }}
                        </div>
                      </td>
                      <td class="text-center">
                        <div>{{ order_item.quantity | currency }}</div>
                        <div class="text-danger">
                          {{ order_item.returnQuantity | currency }}
                        </div>
                      </td>
                      <td class="text-right">
                        <div :class="order_item.prodMode == '退貨品' ? 'text-danger' : ''">
                          {{ order_item.amount | currency }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="9">
                        <div
                          v-if="order.status"
                          class="d-flex mb-2"
                        >
                          <a
                            v-if="order.storageProcessedStatus"
                            href="#"
                            class="btn btn_primary px-2 ml-2 rounded-sm"
                            @click="show_modal"
                          >部分退貨</a>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
                <!-- Modal -->
                <div
                  id="modalReturnItems"
                  class="modal fade"
                  tabindex="-1"
                  aria-labelledby="returnModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5
                          id="returnModalLabel"
                          class="modal-title"
                        >
                          確認退貨內容
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="text-center">
                          訂單編號: <span>{{ order.orderSerial }}</span>
                        </div>
                        <div
                          v-for="return_item in return_items"
                          :key="return_item.serialNumber"
                          class="d-flex"
                        >
                          <div class="p-2 flex-fill text-danger">
                            {{ return_item.prodName }}:
                          </div>
                          <div class="p-2 flex-fill">
                            <select
                              v-model="return_item.inputQuantity"
                              class="form-control form_input"
                              :value="return_item.inputQuantity"
                            >
                              <option
                                v-for="(item, index) in return_item.quantity"
                                :key="index"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="d-flex">
                          <div class="p-2 flex-fill text-left">
                            退貨選擇:
                          </div>
                          <div class="p-2 flex-fill">
                            <select
                              id="return_payment_code"
                              v-model="return_paymentCode"
                              name="退款類型"
                              class="form-control form_input"
                            >
                              <option
                                v-for="(item, index) in option.returnItemType"
                                :key="index"
                                :value="item.code"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div>
                          <label for="bill_requestNumber">回饋碼:</label>
                          <input
                            id="bill_requestNumber"
                            v-model="return_requestNumber"
                            type="text"
                            name="回饋碼"
                            class="form-control"
                            :class="{'is-invalid': errors.has('回饋碼')}"
                            placeholder="輸入回饋碼"
                          >
                        </div>
                        <div>
                          <label for="bill_memo">備註:</label>
                          <input
                            id="bill_memo"
                            v-model="return_memo"
                            type="text"
                            name="備註"
                            class="form-control"
                            :class="{'is-invalid': errors.has('備註')}"
                            placeholder="備註"
                          >
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button
                          type="button"
                          class="btn btn-primary"
                          @click="return_order_item(order)"
                        >
                          確認退貨
                        </button>
                        <button
                          type="button"
                          class="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          關閉
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="nav_tabs === 'order_contact'"
                class="row"
              >
                <div class="col-12 col-xl-6">
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂購人姓名</span>
                      <span>{{ order.pur.purFullname }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂購人手機</span>
                      <span>{{ order.pur.purCelnum }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂購人信箱</span>
                      <span>{{ order.pur.purEmail }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>訂購人地址</span>
                      <span>{{ order.pur.purAddrZip + order.pur.purAddrCountyName + order.pur.purAddrCityName + order.pur.purAddrDetail }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="recFullname">收件人姓名</label>
                      <input
                        id="recFullname"
                        v-model="order.rec.recFullname"
                        v-validate="'required'"
                        type="text"
                        name="收件人姓名"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('edit.收件人姓名')}"
                        data-vv-scope="edit"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>收件人姓名</span>
                      <span>{{ order.rec.recFullname }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_rec'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="recCelnum">收件人手機</label>
                      <input
                        id="recCelnum"
                        v-model="order.rec.recCelnum"
                        v-validate="'required'"
                        type="text"
                        name="收件人手機"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('edit.收件人手機')}"
                        data-vv-scope="edit"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>收件人手機</span>
                      <span>{{ order.rec.recCelnum }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>收件人地址</span>
                      <span>{{ order.rec.recAddrZip + order.rec.recAddrCountyName + order.rec.recAddrCityName + order.rec.recAddrDetail }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="nav_tabs === 'order_price'"
                class="row"
              >
                <div class="col-12">
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>總價</span>
                      <span>{{ order.amount.amountTotal | currency }}元</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>應收金額</span>
                      <span>{{ order.amount.amountReceivables | currency }}元</span>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="nav_tabs === 'order_bill'"
                class="table-responsive"
              >
                <table class="table table-borderless table-hover">
                  <thead class="">
                    <tr>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        項目
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        類型
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        金額
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        狀態
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        付款日期
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        管理者
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        購物金變更
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        回饋碼
                      </th>
                      <th
                        scope="col"
                        class="text-color_primary"
                      >
                        備註
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="bill in order.bill"
                      :key="bill.serialNumber"
                    >
                      <td>{{ bill.paymentName }}</td>
                      <td
                        :class="{ 'text-success': bill.paymentMode === '付款', 'text-danger': bill.paymentMode === '退款' }"
                      >
                        {{ bill.paymentMode }}
                      </td>
                      <td>{{ bill.amount | currency }}</td>
                      <td
                        v-if="bill.paymentMode === '付款' || bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account'"
                        :class="{ 'text-success': bill.payStatus, 'text-danger': !bill.payStatus }"
                      >
                        {{ bill.payStatus ? '已付款' : bill.paymentCode === 'cash_on_delivery_for_blackcat' || bill.paymentCode === 'pay_by_account' ? '程序付款' : '未付款' }}
                      </td>
                      <td
                        v-else
                        class="text-danger"
                      >
                        已退款
                      </td>
                      <td
                        v-if="bill.paymentMode === '付款'"
                      >
                        {{ bill.payDate === '2000-01-01 00:00:00' ? '' : bill.payDate }}
                      </td>
                      <td
                        v-else
                      >
                        {{ bill.returnDate }}
                      </td>
                      <td>{{ bill.paymentMode === '付款' ? bill.payAdminId : bill.returnAdminId }}</td>
                      <td>{{ bill.pointValue }}</td>
                      <td><span>{{ bill.requestNumber }}</span> <span>{{ bill.requestBank }}</span> <span v-if="bill.requestDate !== '2000-01-01'">{{ bill.requestDate }}</span></td>
                      <td>{{ bill.memo }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="nav_tabs === 'order_receipt'"
                class="row"
              >
                <div class="col-12">
                  <div class="order_basic_item">
                    <div class="d-flex align-items-baseline">
                      <span>開立狀態</span>
                      <span :class="{ 'text-success': order.receipt.receiptIssueStatus, 'text-danger': !order.receipt.receiptIssueStatus }">{{ order.receipt.receiptIssueStatus ? '已開立' : '尚未開立' }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_receipt'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="receiptType">發票類型</label>
                      <select
                        id="receiptType"
                        v-model="order.receipt.receiptType"
                        v-validate="'required'"
                        name="發票類型"
                        class="form-control form_input"
                        :class="{'is-invalid': errors.has('edit.發票類型')}"
                        data-vv-scope="edit"
                      >
                        <option
                          value
                          disabled
                          selected
                          hidden
                        >
                          發票類型
                        </option>
                        <option
                          v-for="item in option.receiptType"
                          :key="item"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>發票類型</span>
                      <span>{{ order.receipt.receiptType }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_receipt'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="receiptUbn">統一編號</label>
                      <input
                        id="receiptUbn"
                        v-model="order.receipt.receiptUbn"
                        type="text"
                        name="統一編號"
                        class="form-control form_input"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>統一編號</span>
                      <span>{{ order.receipt.receiptUbn }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_receipt'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="receiptTitle">發票抬頭</label>
                      <input
                        id="receiptTitle"
                        v-model="order.receipt.receiptTitle"
                        type="text"
                        name="發票抬頭"
                        class="form-control form_input"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>發票抬頭</span>
                      <span>{{ order.receipt.receiptTitle }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_receipt'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="receiptNumber">發票號碼</label>
                      <input
                        id="receiptNumber"
                        v-model="order.receipt.receiptNumber"
                        type="text"
                        name="發票號碼"
                        class="form-control form_input"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>發票號碼</span>
                      <span>{{ order.receipt.receiptNumber }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_receipt'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="receiptDate">開立日期</label>
                      <input
                        id="receiptDate"
                        v-model="order.receipt.receiptDate"
                        type="date"
                        name="開立日期"
                        class="form-control form_input"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>開立日期</span>
                      <span>{{ order.receipt.receiptDate === '2000-01-01' ? '' : order.receipt.receiptDate }}</span>
                    </div>
                  </div>
                  <div class="order_basic_item">
                    <div
                      v-if="order_mode === 'edit_receipt'"
                      class="form-group d-flex align-items-center"
                    >
                      <label for="receiptVehicleMobile">手機載具</label>
                      <input
                        id="receiptVehicleMobile"
                        v-model="order.receipt.receiptVehicleMobile"
                        type="text"
                        name="手機載具"
                        class="form-control form_input"
                      >
                    </div>
                    <div
                      v-else
                      class="d-flex align-items-baseline"
                    >
                      <span>手機載具</span>
                      <span>{{ order.receipt.receiptVehicleMobile }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  API_GET_ORDER_LIST,
  API_GET_ORDER_LIST_V2,
  API_GET_ORDER_DETIAL,
  API_CANCEL_ORDER,
  API_UPDATE_ORDER,
  API_UPDATE_ORDER_RECEIPT,
  API_UPDATE_ORDER_BILL,
  API_UPDATE_ORDER_RETURN_ITEM,
} from '@/plugins/api';

export default {
  data() {
    return {
      now_page: 'orders',
      search: {
        /* 搜尋資料 */
        mode: '',
        system: '',
        value: '',
        date: new Date().toISOString().substr(0, 10),
      },
      search_v2: {
        /* 搜尋資料v2 */
        mode: '',
        system: '',
        dt_start: new Date().toISOString().substr(0, 10),
        dt_end: new Date().toISOString().substr(0, 10),
        value_keyword: '',
        option_adv_keyword: false,
        value_adv_keyword: '',
        option_unpay: false,
        option_sort: false,
        option_row: 0,
      },
      order_content: '' /* list: 列表; detail: 訂單細節 */,
      orders: [] /* 訂單資料 */,
      order: {}, /* 訂單細節 */
      order_mode: '',
      return_items: [], /* 確定要退的商品資料 */
      return_paymentCode: '',
      return_requestNumber: '',
      return_memo: '',
      nav_tabs: '',
      pagination: {
        /* 分頁設定 */
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      },
      district_data: [], /* 行政區資料 */
      search_advanced: '', /* 進階搜尋 */
      sort_amount_status: true, /* 金額排序狀態 true: 小到大;false: 大到小 */
      search_same_date: false, /* 結束日期是否相同於起始日期 */
    };
  },
  computed: {
    ...mapGetters('system', ['system', 'option']),
    system_data: {
      get() {
        const vm = this;
        const data = vm.system.slice();
        return data;
      },
    },
    /* 篩選過濾訂單資料 */
    filter_orders() {
      const vm = this;
      return vm.orders.filter((item) => {
        if (vm.search_advanced === '') {
          return item;
        }
        return item.platformCode === vm.search_advanced;
      });
    },
    /* 訂單資料分頁化 */
    filter_Data() {
      const vm = this;
      const dataLen = vm.filter_orders.length;
      const nowPage = vm.pagination.current_page;
      vm.pagination.total_pages = Math.ceil(
        dataLen / vm.pagination.filter_page_size,
      );
      vm.pagination.filter_range_page = [];
      for (let index = 1; index <= vm.pagination.total_pages; index += 1) {
        if (index >= (nowPage - vm.pagination.filter_range) && index <= (nowPage + vm.pagination.filter_range)) {
          vm.pagination.filter_range_page.push({
            index,
          });
        }
      }
      if (nowPage > 1) {
        vm.pagination.has_pre = true;
      } else {
        vm.pagination.has_pre = false;
      }
      if (nowPage === vm.pagination.total_pages) {
        vm.pagination.has_next = false;
      } else {
        vm.pagination.has_next = true;
      }
      return vm.filter_orders.filter(
        (item, index) => index < nowPage * vm.pagination.filter_page_size
          && index >= (nowPage - 1) * vm.pagination.filter_page_size,
      );
    },
  },
  watch: {
    'pagination.filter_page_size': {
      handler: async function initCurrentPage(newValue, oldValue) {
        const vm = this;
        vm.pagination.current_page = 1;
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    const vm = this;
    await vm.$store.dispatch('page_load', true);
    await vm.$store.dispatch('now_page', vm.now_page);
    await vm.get_system();
    await vm.get_system_option();
    await vm.$store.dispatch('page_load', false);
  },
  methods: {
    ...mapActions('system', ['get_system', 'get_system_option']),
    /* 切換頁數 */
    change_page(page = 1) {
      const vm = this;
      vm.pagination.current_page = page;
    },
    async init_pagination() {
      const vm = this;
      vm.pagination = {
        filter_page_size: 20,
        total_pages: 1,
        current_page: 1,
        has_pre: false,
        has_next: false,
        filter_range: 3,
        filter_range_page: [],
      };
    },
    /* 讓結束日期與起始日期相同 */
    async same_date() {
      const vm = this;
      if (vm.search_same_date) {
        vm.search_v2.dt_end = vm.search_v2.dt_start;
      }
    },
    /* 切換搜尋模式 */
    async switch_searchmode() {
      const vm = this;
      setTimeout(() => {
        // vm.search.value = '';
        // vm.search.date = new Date().toISOString().substr(0, 10);
        // vm.errors.clear('search');
        vm.search_v2.value_keyword = '';
        // vm.search_v2.dt_start = new Date().toISOString().substr(0, 10);
        // vm.search_v2.dt_end = new Date().toISOString().substr(0, 10);
        vm.search_v2.option_adv_keyword = false;
        vm.search_v2.value_adv_keyword = '';
        vm.search_v2.option_unpay = false;
        vm.search_v2.option_sort = false;
        vm.search_v2.option_row = 0;
        vm.errors.clear('search');
        vm.$validator.reset();
      }, 100);
    },
    /* switch_nav */
    async switch_nav(content, orderDetailStatus) {
      const vm = this;
      vm.nav_tabs = content;
      vm.nav_tab_detail = orderDetailStatus;
    },
    /* 取得訂單列表 */
    async get_orders() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('search');
      if (vm.search_v2.mode === '') {
        vm.$swal({
          icon: 'warning',
          title: '請選擇模式!',
          confirmButtonText: '關閉視窗',
        });
        return;
      }
      if (vm.search_v2.mode === 3 && vm.search_v2.value_keyword === '') {
        vm.$swal({
          icon: 'warning',
          title: '請輸入關鍵字!',
          confirmButtonText: '關閉視窗',
        });
        return;
      }
      if (validateResult) {
        vm.$store.dispatch('page_load', true);
        vm.search_advanced = '';
        // const data = {
        //   mode: String(vm.search.mode),
        //   systemCode: vm.search.system,
        //   value: vm.search.value === '' ? vm.search.date : vm.search.value,
        // };
        // const response = await API_GET_ORDER_LIST(data);
        let smode = '';
        if (vm.search_v2.mode === 1) {
          smode = 'orderDate';
        } else if (vm.search_v2.mode === 2) {
          smode = 'arriveDate';
        } else {
          smode = 'keyword';
        }
        const data = {
          mode: smode,
          systemCode: vm.search_v2.system,
          dtstart: vm.search_v2.dt_start,
          dtend: vm.search_v2.dt_end,
          valueKeyword: vm.search_v2.value_keyword,
          optionAdvKeyword: vm.search_v2.option_adv_keyword,
          valueAdvKeyword: vm.search_v2.value_adv_keyword,
          optionUnPay: vm.search_v2.option_unpay,
          optionSort: vm.search_v2.option_sort,
          optionRow: Number(vm.search_v2.option_row),
        };
        const response = await API_GET_ORDER_LIST_V2(data);
        const res = response.data;
        // console.log('取得訂單列表 成功', response);
        if (res.code === '0000') {
          await vm.init_pagination();
          vm.orders = res.data;
          vm.order_content = 'list';
          vm.sort_amount_status = true;
          vm.return_items = [];
        }
        vm.$store.dispatch('page_load', false);
      } else if (!validateResult && vm.search.mode === 3 && vm.search.value === '') {
        vm.$swal({
          icon: 'warning',
          title: '請輸入搜尋值!',
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 取得訂單細節 */
    async get_oder_detail(orderSerial) {
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const response = await API_GET_ORDER_DETIAL(orderSerial);
      const res = response.data;
      // console.log('取得訂單細節 成功', response);
      if (res.code === '0000') {
        vm.order = res.data;
        vm.order_content = 'detail';
        vm.return_items = [];
      }
      vm.$store.dispatch('page_load', false);
    },
    /* 取消訂單 */
    async cancel_order(order) {
      const vm = this;
      const value = await vm.$swal({
        title: '確定取消訂單?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        html: `
          <p class="text-left">訂單編號: <span>${order.orderSerial}</span></p>
          <p class="text-left text-danger">訂購人: <span class="font-weight-bold" style="font-size: 22px;">${order.purFullname}</span></p>
          <p class="text-left text-danger">金額: <span class="font-weight-bold" style="font-size: 22px;">${order.amountTotal}</span></p>
          <p class="text-left">連絡電話: <span>${order.purCelnum}</span></p>
        `,
        confirmButtonColor: '#dc3545',
        confirmButtonText: '確定',
        cancelButtonColor: '#3085d6',
        cancelButtonText: '取消',
      });
      if (value.isConfirmed) {
        vm.$store.dispatch('page_load', true);
        const response = await API_CANCEL_ORDER(order.orderSerial);
        const res = response.data;
        // console.log('取消訂單 成功', response);
        if (res.code === '0000') {
          await vm.get_orders();
          vm.order_content = 'list';
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
        }
        vm.$store.dispatch('page_load', false);
      }
    },
    /* 確認訂單帳款 */
    async confirm_order_item(order, bill) {
      const vm = this;
      const value = await vm.$swal({
        title: '確定確認訂單款項?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        html: `
          <p class="text-left">訂單編號: <span>${order.orderSerial}</span></p>
          <p class="text-left text-danger">訂購人: <span class="font-weight-bold" style="font-size: 22px;">${order.purFullname}</span></p>
          <p class="text-left text-danger">付款方式: <span class="font-weight-bold" style="font-size: 22px;">${bill.paymentName}</span></p>
          <p class="text-left text-danger">金額: <span class="font-weight-bold" style="font-size: 22px;">${bill.amount}</span></p>
          <p class="text-left">回饋碼/授權6碼: <input id="bill_number" class="form-control form_input"></input></p>
          <p class="text-left">銀行(匯款用): <input id="bill_bank" class="form-control form_input"></input></p>
          <p class="text-left">交易日: <input id="bill_date" type="date" class="form-control form_input"></input></p>
          <p class="text-left">備註: <input id="bill_memo" class="form-control form_input"></input></p>
        `,
        confirmButtonColor: '#dc3545',
        confirmButtonText: '確定',
        cancelButtonColor: '#3085d6',
        cancelButtonText: '取消',
      });
      if (value.isConfirmed) {
        vm.$store.dispatch('page_load', true);
        const orderBillConfirm = {
          serialNumber: bill.serialNumber,
          requestNumber: $('#bill_number').val(),
          requestBank: $('#bill_bank').val(),
          requestDate: $('#bill_date').val(),
          memo: $('#bill_memo').val(),
        };
        const response = await API_UPDATE_ORDER_BILL(orderBillConfirm);
        const res = response.data;
        // console.log('款項確認 成功', response);
        if (res.code === '0000') {
          await vm.get_orders();
          vm.order_content = 'list';
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
        }
        vm.$store.dispatch('page_load', false);
      }
    },
    /* 部分商品退貨 */
    async return_order_item(order) {
      $('#modalReturnItems').modal('hide');
      const vm = this;
      vm.$store.dispatch('page_load', true);
      const returnItemsData = [];
      vm.return_items.forEach((item) => {
        const returnItem = { serialNumber: item.serialNumber, quantity: item.inputQuantity };
        returnItemsData.push(returnItem);
      });
      const orderRuturn = {
        orderSerial: order.orderSerial,
        paymentCode: vm.return_paymentCode,
        requestNumber: vm.return_requestNumber,
        memo: vm.return_memo,
        returnItems: returnItemsData,
      };
      const response = await API_UPDATE_ORDER_RETURN_ITEM(orderRuturn);
      const res = response.data;
      // console.log('款項確認 成功', response);
      if (res.code === '0000') {
        await vm.get_orders();
        vm.order_content = 'list';
        vm.$swal({
          icon: 'success',
          title: '成功',
          text: res.text,
          confirmButtonText: '關閉視窗',
        });
      }
      vm.$store.dispatch('page_load', false);
    },
    show_modal() {
      $('#modalReturnItems').modal();
    },
    /* 選擇是否退貨 */
    item_check(item, e) {
      const vm = this;
      const itm = {
        serialNumber: item.serialNumber,
        prodName: item.prodName + item.standard,
        quantity: item.quantity - item.returnQuantity,
        inputQuantity: 1,
      };
      if (e.target.checked) {
        vm.return_items.push(itm);
      } else {
        const i = vm.return_items.map((itm) => itm.serialNumber).indexOf(itm.serialNumber);
        vm.return_items.splice(i, 1);
      }
    },
    /* 更新訂單 收件資料 */
    async update_rec() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('edit');
      if (validateResult) {
        const recData = {
          orderSerial: vm.order.orderSerial,
          recFullname: vm.order.rec.recFullname,
          recCelnum: vm.order.rec.recCelnum,
          recTelnum: vm.order.rec.recTelnum,
          recTelnumExt: vm.order.rec.recTelnumExt,
          recAddrCityCode: vm.order.rec.recAddrCityCode,
          recAddrDetail: vm.order.rec.recAddrDetail,
          customDefine3: vm.order.rec.customDefine3,
          customDefine4: vm.order.rec.customDefine4,
          arriveDate: vm.order.arriveDate,
          arriveTime: vm.order.arriveTime,
          delivery: vm.order.delivery,
          deliverySerial: vm.order.deliverySerial,
          memo: vm.order.memoCustomer,
        };
        const response = await API_UPDATE_ORDER(recData);
        const res = response.data;
        // console.log('更新訂單 成功');
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_oder_detail(vm.order.orderSerial);
          vm.order_mode = '';
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 更新發票資料 */
    async update_receipt() {
      const vm = this;
      const validateResult = await vm.$validator.validateAll('edit');
      if (validateResult) {
        const receiptData = {
          orderSerial: vm.order.orderSerial,
          receiptType: vm.order.receipt.receiptType,
          receiptUbn: vm.order.receipt.receiptUbn,
          receiptTitle: vm.order.receipt.receiptTitle,
          receiptNumber: vm.order.receipt.receiptNumber,
          receiptDate: vm.order.receipt.receiptDate,
          receiptVehicleMobile: vm.order.receipt.receiptVehicleMobile,
        };
        const response = await API_UPDATE_ORDER_RECEIPT(receiptData);
        const res = response.data;
        // console.log('更新訂單 成功');
        if (res.code === '0000') {
          vm.$swal({
            icon: 'success',
            title: '成功',
            text: res.text,
            confirmButtonText: '關閉視窗',
          });
          await vm.get_order_detail(vm.order.orderSerial);
          vm.order_mode = '';
        }
      } else {
        let errorText = '';
        vm.errors.items.forEach((item, index) => {
          errorText += `<p class="text-left">${index + 1}. 請正確填寫 ${item.field} 欄位</p>`;
        });
        vm.$swal({
          icon: 'error',
          title: '錯誤!',
          html: errorText,
          confirmButtonText: '關閉視窗',
        });
      }
    },
    /* 取得行政區 */
    async filter_district() {
      const vm = this;
      vm.option.address.forEach((item) => {
        if (item.addrCountyName === vm.order.rec.recAddrCountyName) {
          vm.district_data = item.cities;
        }
      });
      await vm.get_city_code();
    },
    /* 取得郵遞區號 */
    async get_city_code() {
      const vm = this;
      if (vm.district_data.length !== 0) {
        vm.district_data.forEach((item) => {
          if (item.addrCityName === vm.order.rec.recAddrCityName) {
            vm.order.rec.recAddrCityName = item.addrCityName;
            vm.order.rec.recAddrCityCode = item.addrCityCode;
          }
        });
      }
    },
    async sort_data(sortType) {
      const vm = this;
      vm.orders.sort(vm.compare(sortType));
    },
    compare(type) {
      const vm = this;
      let value;
      switch (type) {
        case 'amountTotal':
          if (!vm.sort_amount_status) {
            vm.sort_amount_status = !vm.sort_amount_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val1 - val2;
            };
          } else {
            vm.sort_amount_status = !vm.sort_amount_status;
            value = (a, b) => {
              const val1 = a[type];
              const val2 = b[type];
              return val2 - val1;
            };
          }
          break;
        default:
          break;
      }
      return value;
    },
  },
};
</script>
